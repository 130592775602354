import React, { useState } from "react";
import Pagination from "./Pagination"; // Đường dẫn đến component Pagination

const WhitelistTable = ({ addresses }) => {

    const itemsPerPage = 10;
    const [currentPage, setCurrentPage] = useState(1);
    const [isCopied, setIsCopied] = useState(false);
    const [copiedAddress, setCopiedAddress] = useState("");

    const copyToClipboard = (address) => {
        navigator.clipboard.writeText(address).then(() => {
            setIsCopied(true);
            setCopiedAddress(address);
            // Sau một khoảng thời gian (ví dụ: 2 giây), đặt trạng thái isCopied về false để ẩn biểu tượng "check"
            setTimeout(() => {
                setIsCopied(false);
                setCopiedAddress("");
            }, 2000);
        });
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    if (!addresses) {
        return (null)
    }
    const addressesToShow = addresses.slice(startIndex, endIndex);

    return (
        <div className="ant-collapse-content ant-collapse-content-active" style={{ border: 0 }}>
            <div className="ant-collapse-header">User whitelist</div>
            <div className="ant-collapse-content-box">
                <table>
                    <thead>
                        <tr>
                            <th>Address ({addresses?.length})</th>
                        </tr>
                    </thead>
                    <tbody>
                        {addressesToShow.map((address, index) => (
                            <tr key={index}>
                                <td>
                                    <span className="ant-typography">{address}</span>
                                    <div
                                        role="button"
                                        tabIndex="0"
                                        className="ant-typography-copy"
                                        aria-label="Copy"
                                        style={{
                                            border: "0px",
                                            background: "transparent",
                                            padding: "0px",
                                            lineHeight: "inherit",
                                            display: "inline-block",
                                        }}
                                        onClick={() => copyToClipboard(address)}
                                    >
                                        {isCopied && copiedAddress === address ? (
                                            <span role="img" aria-label="check" className="anticon anticon-check">
                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="check" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                    <path d="M912 190h-69.9c-9.8 0-19.1 4.5-25.1 12.2L404.7 724.5 207 474a32 32 0 00-25.1-12.2H112c-6.7 0-10.4 7.7-6.3 12.9l273.9 347c12.8 16.2 37.4 16.2 50.3 0l488.4-618.9c4.1-5.1.4-12.8-6.3-12.8z"></path>
                                                </svg>
                                            </span>
                                        ) : (
                                            <span role="img" aria-label="copy" className="anticon anticon-copy">
                                                <svg viewBox="64 64 896 896" focusable="false" data-icon="copy" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                                    <path d="M832 64H296c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h496v688c0 4.4 3.6 8 8 8h56c4.4 0 8-3.6 8-8V96c0-17.7-14.3-32-32-32zM704 192H192c-17.7 0-32 14.3-32 32v530.7c0 8.5 3.4 16.6 9.4 22.6l173.3 173.3c2.2 2.2 4.7 4 7.4 5.5v1.9h4.2c3.5 1.3 7.2 2 11 2H704c17.7 0 32-14.3 32-32V224c0-17.7-14.3-32-32-32zM350 856.2L263.9 770H350v86.2zM664 888H414V746c0-22.1-17.9-40-40-40H232V264h432v624z"></path>
                                                </svg>
                                            </span>
                                        )}
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <div className="has-text-centered mt-2">
                    <Pagination currentPage={currentPage} totalPages={Math.ceil(addresses.length / itemsPerPage)} onPageChange={handlePageChange} />
                </div>
            </div>
        </div>
    );
};

export default WhitelistTable;