import React, { useState } from "react";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
// import { addRef } from "service";

const Login = () => {
    const state = useSelector((state) => state);
    return (
        <div className="staking-block__content">

            <div className="col-3"></div>
        </div>
    );
};

export default Login;
