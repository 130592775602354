import Layout from "components/Layout";
import Farms from "views/pages/farms";
import Launchpad from "views/pages/launchpad";
import Infomation from "views/pages/launchpad";
import Login from "views/pages/login";
import Pools from "views/pages/top";
import Staking from "views/pages/stakings";
import Ifo from "views/pages/ifo";
import Home from "views/pages/home";
import Airdrop from "views/pages/airdrop";
import NFTs from "views/pages/nfts";
import BUYNFT from "views/pages/buyNft";
import MARKET from "views/pages/market";
import StakingNft from "views/pages/stakingsNft";
import Bots from "views/pages/bots";
import MintNft from "views/pages/mintNft.js";
import Profit from "views/pages/profit/index";

const routers = [
  
  // {
  //   title: "MintNft",
  //   path: "/mint-nft",
  //   component: MintNft,
  //   layout: Layout,
  //   exact: true,
  // },
  {
    title: "Home",
    path: "/",
    component: Home,
    layout: Layout,
    exact: true,
  },
  {
    title: "Home",
    path: "/home",
    component: Home,
    layout: Layout,
    exact: true,
  },
  // {
  //   title: "Staking",
  //   path: "/staking",
  //   component: Staking,
  //   layout: Layout,
  //   exact: true,
  // },
  {
    title: "Profit",
    path: "/profit",
    component: Profit,
    layout: Layout,
    exact: true,
  },
  // {
  //   title: "Bots",
  //   path: "/bots",
  //   component: Bots,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "Farms",
  //   path: "/farms",
  //   component: Farms,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "Swap",
  //   path: "/swap",
  //   component: "",
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "Airdrop",
  //   path: "/airdrop",
  //   component: Airdrop,
  //   layout: Layout,
  //   exact: true,
  // },

  {
    title: "Nfts",
    path: "/nfts",
    component: NFTs,
    layout: Layout,
    exact: true,
  },
  // {
  //   title: "Staking NFTs",
  //   path: "/staking-nfts",
  //   component: StakingNft,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "Buy NFTs",
  //   path: "/buy-nfts",
  //   component: BUYNFT,
  //   layout: Layout,
  //   exact: true,
  // },
  {
    title: "Market",
    path: "/market",
    component: MARKET,
    layout: Layout,
    exact: true,
  },

  // {
  //   title: "Prediction",
  //   path: "/prediction",
  //   component: Pools,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "Events",
  //   path: "/events",
  //   component: Pools,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "NFTs",
  //   path: "/nfts",
  //   component: Pools,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "Farms",
  //   path: "/farms",
  //   component: Farms,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "Launchpad",
  //   path: "/launchpad",
  //   component: Launchpad,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "ifo",
  //   path: "/ifo-fish-tech",
  //   component: Ifo,
  //   layout: Layout,
  //   exact: true,
  // },
  // {
  //   title: "Login",
  //   path: "/Login",
  //   component: Login,
  //   layout: Layout,
  //   exact: true,
  // },
];

export { routers };
