
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routers } from "router";

const ModalConnect = ({ props, close }) => {


    return (
        <div className="custommodal custommodal_confirm">

            <div className="modals">
                <div className="title_"> <div>Successful</div></div>
                <div className="close" onClick={close}></div>

                <div className="modal-body-custom">
                    <div className="content">
                        <div className="title_text_main-icon ">
                            <h2 className="font-main">Top 15  Holders</h2>


                        </div>
                        <div className="dog_">
                            <img src="img/dog.png" alt="" />
                        </div>
                        <div className="dog_btn">
                            <button>0.95 BNB</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalConnect;
