import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routers } from "router";

const Footer = ({ props }) => {
    const state = useSelector((state) => state);
    // console.log(state);
    const onConnect = () => {
        if (window.ethereum) {
            window.ethereum.enable().then(() => {
            });
        }
    };

    return (
        <footer className=" text-center" style={{ display: 'block', }}>
            <div className="sslslsllsdpawpdsa">
                <div className="top-logo row m-0">
                    <div className="logo col-sm-6 col-md-6 col-lg-6">
                        <img src="img/logo.png" alt="" />
                    </div>
                    <div className="buy col-sm-6 col-md-6 col-lg-6">
                        <Link to="/home" style={{color: '#fff'}}>Buy Now</Link>
                        {/* <button className="btn-buy font-second">Buy Now</button> */}
                    </div>
                </div>
                <div className="bottom-logo row m-0">
                    <div className="left ">
                        <span className="font-second">© 2024 Babydogwif</span>
                    </div>
                    <div className="right ">
                        <div className="font-second">Privacy policy</div>
                        <div className="font-second">Terms of services</div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
