// import StakingCard from "components/staking_card";
import ModalConnect from "components/commons/ModalConnect";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import LoadingButton from "components/commons/LoadingButton.js"
import { getInfoMint, groksMint } from "service";
import { NotificationManager } from "react-notifications";
import Web3 from 'web3';
import WhitelistTable from "components/commons/whitelistTable";

// const linkd = window.location.origin
// console.log('====================================');
// console.log(linkd);
// console.log('====================================');

const colors = [1, 2, 3];
const delay = 2500;

const Home = () => {
  const state = useSelector((state) => state);
  useEffect(() => {
    document.title = 'Babydogwif';
  });
  const [priceRoc, setPriceRoc] = useState('0')
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(true);
  const [showModalReward, setShowModalReward] = useState(false);
  const [dataReward, setDataReward] = useState(0);
  const [dataMint, setDataMint] = useState({});
  const [chianId, setChainId] = useState(0)
  const [remainingTimePublic, setRemainingTimePublic] = useState(0);
  const timerRef = useRef(null);
  const [remainingTimePublicT2, setRemainingTimePublicT2] = useState(0);
  const timerRefT2 = useRef(null);

  useEffect(() => {
    const countdown = () => {
      setRemainingTimePublic(prevTime => prevTime - 1);
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [remainingTimePublic]);

  useEffect(() => {
    const countdown = () => {
      setRemainingTimePublicT2(prevTime => prevTime - 1);
    };
    timerRefT2.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRefT2.current);
    };
  }, [remainingTimePublicT2]);

  const formatTime = (time) => {
    const days = Math.floor(time / (3600 * 24));
    const hours = Math.floor((time % (3600 * 24)) / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const timePublicString = formatTime(Math.max(remainingTimePublic, 0));
  const timePublicStringt2 = formatTime(Math.max(remainingTimePublicT2, 0));

  const [index, setIndex] = React.useState(0);
  const timeoutRef = useRef(null);
  const timeoutRefT2 = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  const resetTimeoutT2 = () => {
    if (timeoutRefT2.current) {
      clearTimeout(timeoutRefT2.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);

  useEffect(() => {
    resetTimeout();
    timeoutRefT2.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeoutT2();
    };
  }, [index]);

  // console.log(state);
  useEffect(async () => {
    getData()
    const timer = setInterval(() => {
      getData()
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const getData = async () => {
    let data = await getInfoMint();
    // console.log(data);
    if (!data?.totalMint) {
      return
    }
    // setRemainingTimePublic((data.timeOpen * 1) - Math.round(Date.now() / 1000))
    setDataMint(data);
    setRemainingTimePublic((data.timeOpen * 1) - Math.round(Date.now() / 1000))
    setRemainingTimePublicT2((data.timeOpen * 1) + (30 * 60) - Math.round(Date.now() / 1000))
    // setApprove(data?.stt);
  }

  useEffect(() => {
    if (typeof window.ethereum == 'undefined') {
      return
    }
    const web3 = new Web3(window.ethereum);
    web3.eth.net.getId()
      .then(chainId => {
        setChainId(chainId)
        // console.log(chainId);
      })
      .catch(error => {
        setChainId(0)
        console.error('Error getting chain ID:', error);
      });
  }, [window.ethereum]);

  useEffect(() => {
    setRemainingTimePublic(1706454000 - Math.round(Date.now() / 1000))
    setRemainingTimePublicT2(1706454000 + (30 * 60) - Math.round(Date.now() / 1000))
  }, []);

  const handleSubmit = async (event) => {
    const web3 = new Web3();
    event.preventDefault();

    setLoading(true);
    let data = await groksMint();
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
      setLoading(false)
      return
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    // console.log(data);
    // console.log(data?.events[1]?.raw?.topics);
    // console.log(data?.events[1]?.raw?.topics?.[2]);
    const bigNumber = web3.utils.toBN(data?.events[1]?.raw?.topics?.[2]);
    const number = bigNumber.toString();
    // console.log(number);
    setDataReward(number)
    setShowModalReward(true)
    // console.log(data.events?.BuyNftsResult?.returnValues);
    // setDataReward(data.events?.BuyNftsResult?.returnValues)

    setLoading(false);
  };

  const selectNetwork = async () => {
    let web3 = new Web3(window.ethereum);
    try {
      if (web3.currentProvider) {
        await web3.currentProvider.request({
          method: 'wallet_switchEthereumChain',
          params: [{ chainId: "0x38" }], // Change to the desired chain ID
        });
      }
      window.location.reload();
    } catch (error) {
      console.error('Error switching network:', error);
    }

  }

  return (
    <main className="main-container">

      <div className="main-container-top" style={{ position: 'relative' }}>
        <img src="img/indexfi.png" className="indexfi" />
        <div className="sllsadasodow">
          <img src="./img/bbd472.png" />
        </div>
        <div className="sllsadasodow2">
          <img src="./img/473.png" />
        </div>

      </div>
      <div className="hld90"></div>

      <div style={{ position: 'relative' }}>
        <div className="sllsadasodow">
          <img src="./img/471.png" />
        </div>
        <div className="sllsadasodow2">
          <img src="./img/474.png" />
        </div>
        <div className="container" >

          {/* <section className="service_ ">
            <div className="service_wrap">
              <div>
                <div className="item">
                  <div className="l"><span className="font-second">START IN</span></div>
                  <div className="r">
                    <div className="text-bold tdb">15:00</div>
                    <div className="text_ font-second">28/01/2024 (UTC)</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="item">
                  <div className="l"><span className="font-second">TOTAL MINTED</span></div>
                  <div className="r">
                    <div className="text-bold tdb">{200 - (dataMint?.totalMint * 1)}</div>
                    <div className="text-bold tdb">{0}</div>
                  </div>
                </div>
              </div>
              <div>
                <div className="item">
                  <div className="l"><span className="font-second">TOTAL NFTS</span></div>
                  <div className="r"> <div className="text-bold tdb">200</div></div>
                </div>
              </div>
              <div>
                <div className="item">
                  <div className="l"><span className="font-second">TYPE</span></div>
                  <div className="r"> <div className="text-bold tdb">WHITELIST (FCFS)</div></div>
                </div>
              </div>
              <div>
                <div className="item">
                  <div className="l"><span className="font-second">TOTAL WHITELIST</span></div>
                  <div className="r"> <div className="text-bold tdb">200</div></div>
                </div>
              </div>
            </div>

          </section> */}
          {/* <div className="hld180"></div> */}
          <section className="list-lidis row m-0">
            <div className="col-sm-3">
              <div className="item">
                <div className="l"><span className="font-second">TOTAL MINTED</span></div>
                <div className="r pl-4">
                  {/* <div className="text-bold tdb">0</div> */}
                  <div className="text-bold tdb">{200 - (dataMint?.totalMint * 1)}</div>
                  {/* <div className="text_ font-second">28/01/2024 (UTC)</div> */}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="item">
                <div className="l"><span className="font-second">TOTAL NFTS</span></div>
                <div className="r pl-4">
                  <div className="text-bold tdb">200</div>
                  {/* <div className="text_ font-second">28/01/2024 (UTC)</div> */}
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="item">
                <div className="l"><span className="font-second">TYPE</span></div>
                <div className="r">
                  {/* <div className="text-bold tdb">0</div> */}
                  <div className="text_ font-second">WHITELIST (FCFS)</div>
                </div>
              </div>
            </div>
            <div className="col-sm-3">
              <div className="item">
                <div className="l"><span className="font-second">TOTAL WHITELIST</span></div>
                <div className="r pl-4">
                  <div className="text-bold tdb">200</div>
                  {/* <div className="text_ font-second">28/01/2024 (UTC)</div> */}
                </div>
              </div>
            </div>

            {/* <div className="item col-sm-3">
              <div className="l"><span className="font-second">TOTAL MINTED</span></div>
              <div className="r">
                <div className="text-bold tdb">0</div>
              </div>
            </div>
            <div className="item col-sm-3">
              <div className="l"><span className="font-second">TOTAL NFTS</span></div>
              <div className="r">
                <div className="text-bold tdb">200</div>
              </div>
            </div>
            <div className="item col-sm-3">
              <div className="l"><span className="font-second">TYPE</span></div>
              <div className="r">
                <div className="text_ font-second">WHITELIST (FCFS)</div>
              </div>
            </div>
            <div className="item col-sm-3">
              <div className="l"><span className="font-second">TOTAL WHITELIST</span></div>
              <div className="r">
                <div className="text-bold tdb">200</div>
              </div>
            </div> */}
          </section>
          <div className="hld30"></div>
          <section>
            <div className="lslslslsla mt-4">
              <span style={{ color: 'rgb(255, 199, 28)' }}>
                Countdown Timer
              </span>
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ color: 'rgb(255 199 28)' }}>Start in:</span> 15:00 28/01/2024 (UTC)
            </div>
            <div className="mt-4 mb-4">
              <div className="has-text-right">
                <div className="has-text-centered" style={{ textAlign: 'center' }}>
                  <strong style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <div className="has-background-danger-light mr-1" >
                      {timePublicString.split(':')[0]}
                      <div className="lslsalopgof">DAYS</div>
                    </div>
                    <span className="lslslaodosad">:</span>
                    <div className=" has-background-danger-light mr-1" >
                      {timePublicString.split(':')[1]}
                      <div className="lslsalopgof">HOURS</div>
                    </div>
                    <span className="lslslaodosad">:</span>
                    <div className=" has-background-danger-light mr-1" >
                      {timePublicString.split(':')[2]}
                      <div className="lslsalopgof">MINUTES</div>
                    </div>
                    <span className="lslslaodosad">:</span>
                    <div className=" has-background-danger-light mr-1" >
                      {timePublicString.split(':')[3]}
                      <div className="lslsalopgof">SECONDS</div>
                    </div>
                  </strong>
                </div>
              </div>
            </div>
          </section>

          <section>
            <div className="lslslslsla mt-4">
              <span style={{ color: 'rgb(255, 199, 28)', fontSize: 20 }}>
                Countdown timer T2
              </span>
            </div>
            <div style={{ textAlign: 'center' }}>
              <span style={{ color: 'rgb(255 199 28)' }}>Start in:</span> 15:30 28/01/2024 (UTC)
            </div>
            <div className="mt-4 mb-4 lslsososoosdlas">
              <div className="has-text-right" style={{ fontSize: 20 }}>
                <div className="has-text-centered" style={{ textAlign: 'center' }}>
                  <strong style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <div className="has-background-danger-light mr-1" >
                      {timePublicStringt2.split(':')[0]}
                      <div className="lslsalopgof" style={{ fontSize: 10 }}>DAYS</div>
                    </div>
                    <span className="lslslaodosad">:</span>
                    <div className=" has-background-danger-light mr-1" >
                      {timePublicStringt2.split(':')[1]}
                      <div className="lslsalopgof" style={{ fontSize: 10 }}>HOURS</div>
                    </div>
                    <span className="lslslaodosad">:</span>
                    <div className=" has-background-danger-light mr-1" >
                      {timePublicStringt2.split(':')[2]}
                      <div className="lslsalopgof" style={{ fontSize: 10 }}>MINUTES</div>
                    </div>
                    <span className="lslslaodosad">:</span>
                    <div className=" has-background-danger-light mr-1" >
                      {timePublicStringt2.split(':')[3]}
                      <div className="lslsalopgof" style={{ fontSize: 10 }}>SECONDS</div>
                    </div>
                  </strong>
                </div>
              </div>
            </div>
          </section>

          <div className="hld30"></div>
          <section className="univer_">
            <div className="title_text_main">
              <h2 className="font-main">Universe NFT INO</h2>
            </div>
            <div className="univer_wrap row">
              <div className="col-sm-12 col-md-5 col-lg-5">
                <div className="">
                  <img src="img/bg1.png" alt="" />
                </div>
              </div>
              <div className="col-sm-12 col-md-7 col-lg-7">
                <div className="content-un-wrap">

                  <div className="content-un">
                    <h3 className="font-main">Universe NFT</h3>
                    <p>INO Box is a gift that allows investors who love BabyDogWif to own our NFT with many benefits behind it!</p>
                    <p>Be quick because the number of BOXes will be limited and the benefits behind NFTs will bring you a lot of profit!</p>
                  </div>
                  <div className="info-un">
                    <h3 className="t text-y"><span>Price a Box:</span></h3>
                    <div className="t-icon">
                      <div className="t-icon-icon"></div>
                      <div className="t-icon-t">1.5 BNB/Box</div>
                    </div>

                    {/* <h3 className="t text-y mt-10"><span>Ammount:</span><span className="r">Purchase Limited</span> </h3>
                    <div className="t-input">
                      <div className="l">
                        <div className="input_">
                          <div className="incre">-</div>
                          <div className="input"><input type="text" /></div>
                          <div className="uncre">+</div>
                        </div>

                      </div>
                      <div className="r"><span>0/10</span></div>
                    </div> */}
                  </div>
                  <div className="procees-bar-wrap mt-20">
                    <div className="process-bar">
                      <div className="percent" style={{ width: `${(200 - (dataMint?.totalMint * 1)) * 100 / 200}%` }}></div>
                      {/* <div className="percent" style={{ width: `${0}%` }}></div> */}
                    </div>
                    <div className="info-process mt-2">
                      <div className="l"><span className="font-second">{200 - (dataMint?.totalMint * 1)} </span></div>
                      {/* <div className="l"><span className="font-second">{0} </span></div> */}
                      <div className="r"><span className="font-second flex flex-right">200</span></div>
                    </div>
                  </div>

                  <div className="btn_wrap mt-20">
                    {
                      loading ?
                        <LoadingButton type="button" className="btn_ btn_def" />
                        :
                        (
                          state.address ? (
                            chianId == 56 ?

                              <>
                                <div>
                                  <button className="btn_ btn_def" onClick={handleSubmit} disabled={loading}>
                                    Mint
                                  </button>
                                  {
                                    dataMint?.getWhitelist?.indexOf(state.address) != -1 ?
                                      <div style={{ fontSize: 12 }}>(You are whitelisted)</div>
                                      :
                                      <div style={{ fontSize: 12 }}>(You are not whitelisted)</div>
                                  }
                                </div>
                              </>
                              :
                              <button className="btn_ btn_def" onClick={selectNetwork}>
                                <span style={{ fontSize: 15 }}>Switch Network</span>
                              </button>
                          ) : (
                            <button
                              type="button"
                              className="btn_ btn_def"
                              onClick={() => { document.getElementById("connectWallet").click() }}
                            >
                              {/* <img src="/assets/images/icon-walet.svg" alt="" /> */}
                              <span>Connect wallet</span>
                            </button>
                          )
                        )
                    }
                  </div>

                  <WhitelistTable addresses={dataMint?.getWhitelist} />
                </div>
              </div>
            </div>

          </section>
          <div className="hld180"></div>
          <section className="work_ olllllkaksmdjwdas">
            <img src="./img/Frame111.png" />
          </section>

          <section className="work_ lslsallalsldksad">
            <div className="title_text_main h-m">
              <h2 className="font-main">How Does This Work?</h2>
            </div>
            <div className="univer_wrap  row">
              <div className="col-sm-12 col-md-6 col-lg-4 flex flex-center">
                <img className="img" src="img/gd.png" alt="" />
              </div>
              <div className="col-sm-12 col-md-6 col-lg-8 mt-10 content_">
                <div className="title_text_main">
                  <h2 className="font-main">How Does This Work?</h2>
                </div>
                <div className="content-un-wrap">

                  <div className="content-un">
                    <p>The owner of BabyDogWif is one of the most trusted members of the project and deserves many privileges that only you have!</p>
                  </div>
                  <div className="content row">
                    <div className="col-sm-12 col-md-6 col-lg-6">
                      <div className="title_text_main-icon ">
                        <div className="icon"><img src="img/1.png" alt="" /> </div>
                        <h2 className="">Whitelist Spots</h2>
                      </div>
                      <div className="text-content  mt-10"><p>The first benefit offered is Whitelist placement in the upcoming BabyDogWif Presale. You will become one of the first investors to own tokens at the best price!</p></div>
                    </div>
                    <div className="col-sm-12 col-md-6 col-lg-6 ">
                      <div className="title_text_main-icon">
                        <div className="icon"><img src="img/3.png" alt="" /></div>
                        <h2 className="">Unlock More In Future!</h2>
                      </div>

                      <div className="text-content mt-10"><p>Perks will be integrated and bring many benefits to NFT holders in the future. Don't think that BabyDogWif is just for whitelist slot!</p></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div className="hld120"></div>
        </div>
      </div>



    </main>
  );
};

export default Home;
