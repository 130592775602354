import React, { useRef, useEffect } from 'react';

const VideoPlayer = ({ }) => {
    const videoRef = useRef(null);

    const handleVideoEnd = () => {
        videoRef.current.play();
    };

    const autoplayL = () => {
        setTimeout(() => {
            videoRef.current.play().catch(e => {
                autoplayL()
            })
        }, 1000)
    }
    useEffect(() => {
        autoplayL()
    }, []);

    return (
        <video
            style={{ maxWidth: 250, width: "100%", borderRadius: 10, border: "1px solid #fff" }}
            ref={videoRef}
            autoPlay={true}
            onEnded={() => handleVideoEnd()}
        >
            <source src="./img/nft.mp4" type="video/mp4" />
        </video>
    );
};

export default VideoPlayer;