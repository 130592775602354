import React from "react";
import Footer from "./Footer";
import Header from "./Header";

import { BrowserRouter, Route, Switch, Redirect, useHistory, useLocation, useRouteMatch } from "react-router-dom";
const Layout = ({ children, title }) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();

  const pname = location?.pathname.split("/")[1]
  const pnamecr = location?.pathname;
  return (
    <div className={`container-root ${pname}`}>
      <Header />
      <div className="bg_over"></div>
      <main className="container-fluid p-0">
        
        {/* <div className="staking-block container p-0" > */}
          <div className="page-head staking-block__header" style={{ display: "none" }}>
            <h2 className="header-title">{title}</h2>
            <div
              className="buttons"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-bs-html="true"
              title=" Displayed data will auto-refresh after <span className='timer'>12 seconds</span>. Click this circle to update manually. "
            >
              <div className="progress progress-circle">
              </div>
            </div>
          </div>
          {children}
        {/* </div> */}
      </main>
      <Footer />
    </div>
  );
};

export default Layout;
