// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { NotificationManager } from "react-notifications";
import {
  approveAllStakingNft, checkApproveStakingNft, fcStakingNft, packagesStakingNft, stakingNftWithdraw, unStakingNft,

  getListNftMy,
  approveAll,
  checkApproveNft,
  fcSellMarket,
  fcmining
} from '../../../service';
import ModalNft from "components/commons/ModalNft";
import ModalNft2 from "components/commons/ModalNft2";
import ModalConnect from "components/commons/ModalConnect";
import ModalMinted from "components/commons/ModalMinted";
import VideoPlayer from "components/commons/VideoPlayer";

const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const NFTs = (props) => {
  // useEffect(() =>
  // {
  //   document.title = 'NFTs | AIT';
  // });

  const state = useSelector((state) => state);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [approveNftMarket, setApproveNftMarket] = useState(false);
  const [approveNftMining, setApproveNftMining] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vlOW, setVlOW] = useState("");
  const [idCurrenSelect, setIdCurrenSelect] = useState("0");
  const [amountSell, setAmountSell] = useState(0);
  const [myNft, setMyNft] = useState([]);

  const [isData, setIsData] = useState({});

  // const videoRef = useRef(null);
  // const videoRefs = Array.from({ length: 200 }, () => useRef(null));

  // const handleVideoEnd = (index) => {
  //   // Bắt sự kiện khi video kết thúc và chơi lại nó
  //   // videoRef.current.play();
  //   // videoRefs.forEach((ref) => ref.current.play());
  //   videoRefs[index].current.play();
  // };
  // useEffect(() => {
  //   // Bắt đầu video khi component được tạo
  //   videoRef.current.play();
  // }, []);

  // const audioRef = useRef(null);

  // useEffect(() => {
  //   videoRef.current.play();
  // }, []);

  // useEffect(() => {
  //   const handleAudioEnded = () => {
  //     audioRef.current.currentTime = 0;
  //     audioRef.current.play();
  //   };
  //   audioRef.current.addEventListener('ended', handleAudioEnded);
  //   return () => {
  //     audioRef.current.removeEventListener('ended', handleAudioEnded);
  //   };
  // }, []);

  // const autoplayL = () => {
  //   setTimeout(() => {
  //     videoRefs.forEach((ref) => ref.current.play().catch(e => {
  //       autoplayL()
  //     }));
  //   }, 1000)
  // }
  // useEffect(() => {
  //   autoplayL()
  // }, []);


  // useEffect(() => {
  //   setInterval(() => {
  //     getData();
  //   }, 1000);
  // }, []);

  useEffect(() => {
    getNft()
    setInterval(() => {
      getNft()
    }, 5000);
  }, []);


  const getNft = async () => {
    const listNft = await getListNftMy();
    // console.log(listNft);
    setMyNft(listNft)

  };


  const sell = async (data) => {
    setIdCurrenSelect(data)
    setShowModal(true)
  }

  const mining = async (data) => {
    data.typeCuren = 'mining';
    console.log(data);
    const _checkApproveNft = await checkApproveNft(data.id);
    console.log(_checkApproveNft);
    setApproveNftMining(_checkApproveNft.sttMining)
    setIsData(data)
    handleShow();
  }


  const handChange = (evt) => {
    const BNB = evt.target.value;
    console.log(BNB);
    setAmountSell(BNB)
  };
  const _approveNft = async (val) => {
    setLoading(true);
    await approveAll(val)
    const _checkApproveNft = await checkApproveNft(isData.id);
    setApproveNftMarket(_checkApproveNft.sttMarket)
    setApproveNftMining(_checkApproveNft.sttMining)
    setLoading(false);
  };

  const _sellNft = async () => {
    // console.log(amountSell);
    // const _checkApproveNft = await checkApproveNft(data);
    // console.log(_checkApproveNft);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(true)
    let _checkApproveNft = await checkApproveNft(idCurrenSelect);
    // console.log(ssaaf3243);
    // return
    if (!_checkApproveNft.sttMarket) {
      let dloasltr = await approveAll('sell');
      // if (!dloasltr) {
      //   setLoading(false)
      //   return
      // }
    }

    let data = await fcSellMarket(idCurrenSelect, (vlOW));
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Sell Success", "Success", 10000);
      // handleClose()
      setShowModal(false)
    }
    // const _checkApproveNft = await checkApproveNft(isData.id);
    // console.log(_checkApproveNft.sttMarket);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(false);
  };

  const _miningNft = async (num) => {
    setLoading(true)

    let data = await fcStakingNft(isData.id, num);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Mining Success", "Success", 10000);
      handleClose()
    }
    // const _checkApproveNft = await checkApproveNft(isData.id);
    // console.log(_checkApproveNft.sttMarket);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(false);
  };


  return (
    <div className="site-content site-main-content__buy-sft ">

      <div className=" max-w-1136">
        <div className="hld30"></div>
        <div className="tabs-wrap">
          <div className="tabs">
            <div className="tab-item tab-item1">TOTAL</div>
            <div className="tab-item tab-item2">{myNft.length} NFT</div>
          </div>
        </div>
        {/* <audio ref={audioRef} src={"./img/nft.mp4"} /> */}
        {/* <video
          style={{ maxWidth: 360, width: "100%", borderRadius: 10, border: "2px solid #fff" }}
          ref={videoRef}
          autoPlay={true}
          onEnded={handleVideoEnd}
        >
          <source src="./img/nft.mp4" type="video/mp4" />
        </video> */}
        <div className="row manage-your-wallet m-0">
          {
            (myNft.length) == 0 ?
              <h3 style={{ textAlign: 'center', marginTop: 70, color: '#fff' }}>No data!!!</h3>
              :
              myNft.map((data, keys) => {
                return (
                  <div className="col col-12 col-sm-3 mt-4" style={{ display: 'flex', justifyContent: 'center' }} key={keys}>
                    <div className="sadasdasds">
                      <div className="sadds">
                        <VideoPlayer />
                        <div className="price mb-2 mt-2" style={{ color: '#fff' }}>ID: {data}</div>
                        <div className="row m-0 harvest">

                          <div className="col-12 p-0 btn-connect-wallet btn-connect-walletlisdis" onClick={() => {  }}>
                            <button type="button" className="connect-wallet btn-connect-walletlisdis">Sell</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
          }
        </div>
      </div>
      <div className="hld180"></div>
      {/* <ModalMinted/> */}
      {/* <ModalConnect /> */}
      {/* <ModalNft2/> */}

      {
        showModal ?
          <Modal show={true} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{}}>Price (BNB)</p>
              <input style={{ width: '100%' }} className="input" type="text" id="newOwner" name="newOwner" value={vlOW} onChange={(e) => setVlOW(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
              <div className="has-text-centered">
                {
                  loading ?
                    <div className="col-12 p-0 btn-connect-wallet btn-connect-walletlisdis">
                      <LoadingButton type="button" className="connect-wallet" />
                    </div>
                    :
                    (
                      // <button type="submit" className="ant-btn ant-btn-primary btn btn-primary" onClick={_sellNft}>
                      //   <span>Sell</span>
                      // </button>
                      <div className="col-12 p-0 btn-connect-wallet btn-connect-walletlisdis" onClick={_sellNft}>
                        <button type="button" className="connect-wallet">Sell</button>
                      </div>
                    )
                }
              </div>
            </Modal.Footer>
          </Modal>
          : null
      }
    </div >
  );
};

export default NFTs;
