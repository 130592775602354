// import StakingCard from "components/staking_card";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import
  {
    submitAirdrop
  } from "service";
import { NotificationManager } from "react-notifications";
const Airdrop = () =>
{
  const state = useSelector((state) => state);

  const [utg, setUtg] = useState("");
  const [utw, setUtw] = useState("");
  const [rs1, setRs1] = useState("");
  const [rs2, setRs2] = useState("");
  const [address, setAddress] = useState("");

  const [value, setValue] = React.useState("No");
  const [load, setLoad] = useState(false);

  // utg, utw, rs1, rs2, address
  const submit = async () =>
  {
    // console.log('lewlew');
    setLoad(true);
    let data = await submitAirdrop(utg, utw, rs1, rs2, value, address);
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Buy Success", "Success", 10000);
    }
    setLoad(false);
  }
  const handleChange = (value) =>
  {
    setValue(value);
  };


  useEffect(() =>
  {
    // interval();
    console.log(state.address);
    if (state.address) {
      setAddress(state.address)
    }
  }, [state]);


  // const state = useSelector((state) => state);
  // const loadScript = (src) => {
  //   console.log('document is ', document);
  //   var tag = document.createElement('script');
  //   tag.async = false;
  //   tag.src = src;
  //   console.log(tag);
  //   // console.log(document.getElementsByTagName('body')[0]);
  //   document.getElementsByTagName('body')[0].appendChild(tag);
  // }

  useEffect(() =>
  {
    document.title = 'Babydogwif';
    // loadScript('https://sweepwidget.com/w/j/w_init.js')
  });

  const RadioButton = ({ label, value, onChange }) =>
  {
    return (
      <label>
        <input type="radio" checked={value} onChange={onChange} />
        {label}
      </label>
    );
  };


  return (
    <div className="pb-4 pt-4">
      <div style={{ textAlign: "center", margin: "auto", maxWidth: 600 }}>
        <img src="/assets/images/logo_text.png" className="logo" alt="phishing-warning" style={{ width: "50px" }} />
        <p className="tskloe">Airdrop & WL</p>
        <p className="tkos">The total reward is 1000$ randomly sent to the 100 luckiest registrants.</p>
        {/* <div className="formslt row"> */}
        <a className="formslt row" target={"_blank"} href="https://twitter.com/AITradeBSC">
          <img className="tklimg col-auto" src="https://cdn-icons-png.flaticon.com/512/4096/4096132.png" alt="phishing-warning" style={{ width: "60px" }} />
          <p className="ikksm col-10">Follow Twitter</p>
        </a>
        {/* </div> */}

        {/* <div className="formslt row">*/}
        <a className="formslt row" target={"_blank"} href="https://t.me/aitradegroup">
          <img className="tklimg col-auto" src="https://www.shareicon.net/data/2015/10/04/112103_telegram_512x512.png" alt="phishing-warning" style={{ width: "60px" }} />
          <p className="ikksm col-10">Join Telegram Group</p>
        </a>
        {/* </div> */}

        {/* <div className="formslt row"> */}
        <a className="formslt row" target={"_blank"} href="https://t.me/aitradeChannel">
          <img className="tklimg col-auto" src="https://www.shareicon.net/data/2015/10/04/112103_telegram_512x512.png" alt="phishing-warning" style={{ width: "60px" }} />
          <p className="ikksm col-10">Join Channel</p>
        </a>
        {/* </div> */}

        <div className="form-group">
          <div className="mb-2" style={{ color: "#161616", textAlign: 'left' }}>Address</div>
          <input
            type="text"
            className="form-control mb-4"
            name="amount"
            id="amount"
            placeholder=""
            value={address}
            onChange={(e) =>
            {
              setAddress(e.target.value);
            }}
          />
        </div>

        <div className="form-group">
          <div className="mb-2" style={{ color: "#161616", textAlign: 'left' }}>Username Telegram</div>
          <input
            type="text"
            className="form-control mb-4"
            name="amount"
            id="amount"
            placeholder=""
            onChange={(e) =>
            {
              setUtg(e.target.value);
            }}
          />
        </div>
        <div className="form-group">
          <div className="mb-2" style={{ color: "#161616", textAlign: 'left' }}>Username Twitter</div>
          <input
            type="text"
            className="form-control mb-4"
            name="amount"
            id="amount"
            placeholder=""
            onChange={(e) =>
            {
              setUtw(e.target.value);
            }}
          />
        </div>

        {/* <div className="form-group">
          <div className="mb-2" style={{ color: "#161616", textAlign: 'left' }}>Which projects will be listed on AI Trade in the near future? (Information is in the <a target={"_blank"} href="https://docs.
          .net/">docs</a>)</div>
          <input
            type="text"
            className="form-control mb-4"
            name="amount"
            id="amount"
            placeholder=""
            onChange={(e) => {
              setRs1(e.target.value);
            }}
          />
        </div> */}

        <div className="form-group">
          <div className="mb-2" style={{ color: "#161616", textAlign: 'left' }}>What is the name of this project?</div>
          <input
            type="text"
            className="form-control mb-4"
            name="amount"
            id="amount"
            placeholder=""
            onChange={(e) =>
            {
              setRs2(e.target.value);
            }}
          />
        </div>
        <div style={{ textAlign: "left", marginBottom: 30, color: '#000' }}>
          <p >Do you want to sign up for WL? (Note that if you register without buying, you will not receive the Airdrop)</p>
          <RadioButton
            label=" Yes"
            value={value === 'Yes'}
            onChange={() => { handleChange("Yes") }}
          />
          <br></br>
          <RadioButton
            label=" No"
            value={value === 'No'}
            onChange={() => { handleChange("No") }}
          />
        </div>

        {!load ?
          <button
            disabled={load}
            onClick={() =>
            {
              submit()
            }}
            type="button"
            className="btn btn-lg btn-block btn-background-ghost"
          >
            <span>Submit</span>
          </button>

          :

          <button type="button" className={"btn-lg btn-block btn-wide hizvcO"}>
            <div
              className="spinner-border spinner-border-sm"
              role="status"
            >
              <span className="sr-only"></span>
            </div>
          </button>
        }
      </div>
    </div>

    // <div>
    //   aa
    // </div>
  );
};

export default Airdrop;
