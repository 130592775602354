
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routers } from "router";
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useState } from "react";

const ModalConnect = ({ props, close, connectf }) => {
    // const [click, setCLick] = useState(false);
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };
    
    const submit = async () => {
        // if (!isChecked) {
        //     NotificationManager.warning("Read and accept Terms of Use and Privacy Policy")
        //     return
        // }
        connectf("metamask")
    }
    return (
        <div className="custommodal custommodal_connect">

            <div className="modals">
                <div className="title_"> <div>Connect Wallet</div></div>
                <div className="close" onClick={close}></div>

                <div className="modal-body-custom">
                    <div className="conects_">
                        <div className="card_connect" onClick={submit}>
                            <div className="icon_ icon_meta">
                            </div>
                            <div className="text ">
                                Metamask
                            </div>
                        </div>
                        <div className="card_connect" onClick={submit}>
                            <div className="icon_ icon_safe">
                            </div>
                            <div className="text ">
                                SafePal Wallet
                            </div>
                        </div>
                        <div className="card_connect" onClick={submit}>
                            <div className="icon_ icon_way">
                            </div>
                            <div className="text ">
                                Coinbase Wallet
                            </div>
                        </div>
                        {/* <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://avatars.githubusercontent.com/u/18060234?s=280&v=4" style={{}} />
              <div>Coinbase Wallet</div>
            </div> */}

                        <div className="card_connect" onClick={submit}>
                            <div className="icon_ icon_true">
                            </div>
                            <div className="text ">
                                Trust Wallet
                            </div>
                        </div>

                    </div>
                    {/* <div className="checkbox">
                        <label class="custom-checkbox font-second" >
                            <input type="checkbox" id="termsCheckbox" name="terms" checked={isChecked}
                                onChange={handleCheckboxChange} />
                            <span class="checkmark"></span>
                            <span className="labeltext">
                                Read and accept <a href="" className="font-second" >{" "} Terms of Use </a> and <a href="" className="font-second"> Privacy Policy</a>.
                            </span>
                        </label>
                    </div> */}
                </div>
            </div>
        </div>
    );
};

export default ModalConnect;
