import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routers } from "router";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import ModalConnect from "./commons/ModalConnect";
import { getAccount } from "store/address/action";

const Header = ({ props }) => {
  const state = useSelector((state) => state);
  const [click, setCLick] = useState(false)
  const [priceRoc, setPriceRoc] = useState(0)
  const [nav__, setNav] = useState(false)
  // console.log(state);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const [isshhowMenu, setisshhowMenu] = useState(false);
  const _vrConnect = (type) => {
    // console.log(type);
    // if (window.bitkeep) {
    //   window.bitkeep.ethereum.enable().then((a) => {
    //     console.log(a);
    //     window.localStorage.setItem('isConnect', 'connect')
    //     window.localStorage.setItem('typeConnect', type)
    //     handleClose()
    //   });
    // }
    // return
    if (type == 'bitkeep') {
      // console.log('1111');
      if (window.bitkeep) {
        window?.bitkeep?.ethereum.enable().then(() => {
          window.localStorage.setItem('isConnect', 'connect')
          window.localStorage.setItem('typeConnect', type)
          handleClose()
        });
      } else {
        const provider = window.bitkeep && window.bitkeep.ethereum;
        if (!provider) {
          return window.open('https://bitkeep.com/download?type=0&theme=light');
        }
      }
    } else {
      // console.log('2222');
      if (window.ethereum) {
        window.ethereum.enable().then((efss) => {
          // console.log(efss);
          window.localStorage.setItem('isConnect', 'connect')
          window.localStorage.setItem('typeConnect', type)
          dispatch(getAccount());
          handleClose()
        });
      }
    }

  };
  const onConnect = () => {
    handleShow()
    return
    // if (window.ethereum) {
    //   window.ethereum.enable().then(() => {
    //     window.localStorage.setItem('isConnect', 'connect')
    //   });
    // }
  };
  const disConnect = () => {
    window.localStorage.setItem('isConnect', 'disConnect')
    window.localStorage.setItem('typeConnect', 'none')
  };

  // useEffect(() => {
  //   const genRandomKey = async () => {
  //     try {
  //       const ress = await fetch(`https://api.pancakeswap.info/api/v2/tokens/0x2A8E0eF7C92D52Cd7E388E390b646B213eA0c1EF`).then((res) => res.json());
  //       if (ress.data == undefined) {
  //         return
  //       }
  //       setPriceRoc(ress.data.price);
  //     } catch (err) {
  //       setPriceRoc(0.36);
  //       console.log(`err`, err);
  //       return false;
  //     }
  //     // let res;
  //     setInterval(async () => {
  //       try {
  //         const ress = await fetch(`https://api.pancakeswap.info/api/v2/tokens/0x2A8E0eF7C92D52Cd7E388E390b646B213eA0c1EF`).then((res) => res.json());
  //         if (ress.data == undefined) {
  //           return
  //         }
  //         setPriceRoc(ress.data.price);
  //         return false;
  //       } catch (err) {
  //         setPriceRoc(0.36);
  //         console.log(`err`, err);
  //         return false;
  //       }
  //     }, 5000);
  //     return true;
  //   };

  //   genRandomKey();

  // }, []);

  // const getPrice = async () => {
  //   try {
  //     const res = await fetch(`https://api.pancakeswap.info/api/v2/tokens/0x9Ea46142e60560167542DFF28c5e65AC980B9316`).then((res) => res.json());
  //     console.log(res.data.price);
  //     // if (res.stakeInfo.unpaid_balanceOld_ido_v3 == undefined) {
  //     //   res.stakeInfo.unpaid_balanceOld_ido_v3 = [0, 0]
  //     // }
  //     setPriceRoc(res.data.price);
  //   } catch (err) {
  //     setRefBy(0.136);
  //     console.log(`err`, err);
  //     return false;
  //   }
  // };
  const showmenu = () => {
    setisshhowMenu(!isshhowMenu)
  }
  return (
    <header className="site-header">



      <div className="header-container-main max-w-1136">
        <div className="logo">
          <img src="./img/BabyDOGWIF.png" alt="" style={{minWidth: 40}} />
          <h2>Babydogwif</h2>
        </div>
        <div className="menu">
          <ul className="menu-home-mobile">
            <li className="Home"><Link to="/">Home</Link></li>
            <li className="mynft"><Link to="/nfts">My NFTs</Link></li>
            <li className="ino"><Link to="/market">Market</Link></li>
            <li className="sharing"><Link to="/profit">Sharing Profit</Link></li>

          </ul>
        </div>
        <div className="btn-connect-wallet">
          {state.address ? (
            <button className="btn btn-outline-secondary btn-add-coin" onClick={disConnect} style={{ width: "100%", position: 'relative' }}>
              <span style={{ position: 'absolute', left: 5, top: 3 }}>
                <svg className="sssaasd" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-6 h-6 text-primary"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"></path></svg>
              </span>
              <span className="button-title">
                {state.address.slice(0, 6)}...{state.address.slice(-4)}
              </span>
            </button>
          ) : (
            <button
              type="button"
              className="connect-wallet"
              onClick={onConnect}
              id="connectWallet"
            >

              <span>Connect wallet</span>
            </button>
          )}
          {/* <button className="connect-wallet"><span>Connect wallet</span></button> */}
        </div>
        <button class="jkit-hamburger-menu" onClick={showmenu}>
          <svg aria-hidden="true" fill="white"
            class="e-font-icon-svg e-fas-bars" viewBox="0 0 448 512"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z">
            </path>
          </svg>
        </button>
      </div>
      <div className={`menu-mobile  ${isshhowMenu ? "show" : ""}`}>
        <ul className="menu-home-mobile">
          <li className="Home"><Link onClick={showmenu} to="/">Home</Link></li>
          <li className="mynft"><Link onClick={showmenu} to="/nfts">My NFTs</Link></li>
          <li className="ino"><Link onClick={showmenu} to="/market">Market</Link></li>
          <li className="sharing"><Link onClick={showmenu} to="/profit">Sharing Profit</Link></li>

        </ul>
        <div className="btn-connect-wallet">
          {state.address ? (
            <button className="btn btn-outline-secondary btn-add-coin" onClick={disConnect}>
              <span className="button-title">
                {state.address.slice(0, 6)}...{state.address.slice(-4)}
              </span>
            </button>
          ) : (
            <button
              type="button"
              className="connect-wallet"
              onClick={() => { showmenu(); onConnect() }}
              id="connectWallet"
            >
              {/* <img src="/assets/images/icon-walet.svg" alt="" /> */}
              <span>Connect wallet</span>
            </button>
          )}
          {/* <button className="connect-wallet"><span>Connect wallet</span></button> */}
        </div>
      </div>

      {
        show ?
          <ModalConnect close={handleClose} connectf={(ty) => { _vrConnect(ty) }} />
          :
          null
      }
      {/*       
      <Modal show={show} onHide={handleClose} style={{ maxWidth: 350, margin: 'auto' }}>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: '#ff4d4d' }}>
          <div className="row sssrsrs">
            <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://seeklogo.com/images/M/metamask-logo-09EDE53DBD-seeklogo.com.png" style={{}} />
              <div>Metamask</div>
            </div>
            <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://avatars.githubusercontent.com/u/18060234?s=280&v=4" style={{}} />
              <div>Coinbase Wallet</div>
            </div>
            <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://s2.coinmarketcap.com/static/img/coins/200x200/5964.png" style={{}} />
              <div>Trust Wallet</div>
            </div>
            <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://play-lh.googleusercontent.com/uT6ByyNvUeLRMDnMKEC91RrbHftl2EBB58r9vZaNbiYf1F5Twa33_Hx0zYvEfCtiG1kE" style={{}} />
              <div>Safepal</div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </header>
  );
};

export default Header;
