// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { NotificationManager } from "react-notifications";
import {
  approveAllStakingNft, checkApproveStakingNft, fcStakingNft, packagesStakingNft, stakingNftWithdraw, unStakingNft,

  getListNftMy,
  approveAll,
  checkApproveNft,
  fcSellMarket,
  fcmining
} from '../../../service';
import ModalNft from "components/commons/ModalNft";
import ModalNft2 from "components/commons/ModalNft2";
import ModalConnect from "components/commons/ModalConnect";
import ModalMinted from "components/commons/ModalMinted";
import ModalProfit from "components/commons/ModalProfit";
import ModalConfirm from "components/commons/ModalConfirm";


const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const Profit = (props) => {
  // useEffect(() => {
  //   document.title = 'NFTs | AIT';
  // });

  const state = useSelector((state) => state);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(false);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [approveNftMarket, setApproveNftMarket] = useState(false);
  const [approveNftMining, setApproveNftMining] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vlOW, setVlOW] = useState("");
  const [idCurrenSelect, setIdCurrenSelect] = useState("0");
  const [amountSell, setAmountSell] = useState(0);
  const [myNft, setMyNft] = useState([]);
  const [showNotifi, setShowNotifi] = useState(false);
  const [isData, setIsData] = useState({});


  // useEffect(() => {
  //   setInterval(() => {
  //     getData();
  //   }, 1000);
  // }, []);

  useEffect(() => {
    // getNft()
    // setInterval(() => {
    //   getNft()
    // }, 5000);
  }, []);


  const getNft = async () => {
    const listNft = await getListNftMy();
    // console.log(listNft);
    setMyNft(listNft)

  };


  const sell = async (data) => {
    setIdCurrenSelect(data)
    setShowModal(true)
  }

  const mining = async (data) => {
    data.typeCuren = 'mining';
    console.log(data);
    const _checkApproveNft = await checkApproveNft(data.id);
    console.log(_checkApproveNft);
    setApproveNftMining(_checkApproveNft.sttMining)
    setIsData(data)
    handleShow();
  }


  const handChange = (evt) => {
    const BNB = evt.target.value;
    console.log(BNB);
    setAmountSell(BNB)
  };
  const _approveNft = async (val) => {
    setLoading(true);
    await approveAll(val)
    const _checkApproveNft = await checkApproveNft(isData.id);
    setApproveNftMarket(_checkApproveNft.sttMarket)
    setApproveNftMining(_checkApproveNft.sttMining)
    setLoading(false);
  };

  const _sellNft = async () => {
    // console.log(amountSell);
    // const _checkApproveNft = await checkApproveNft(data);
    // console.log(_checkApproveNft);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(true)
    let _checkApproveNft = await checkApproveNft(idCurrenSelect);
    // console.log(ssaaf3243);
    // return
    if (!_checkApproveNft.sttMarket) {
      let dloasltr = await approveAll('sell');
      // if (!dloasltr) {
      //   setLoading(false)
      //   return
      // }
    }

    let data = await fcSellMarket(idCurrenSelect, (vlOW));
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Sell Success", "Success", 10000);
      // handleClose()
      setShowModal(false)
    }
    // const _checkApproveNft = await checkApproveNft(isData.id);
    // console.log(_checkApproveNft.sttMarket);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(false);
  };

  const _miningNft = async (num) => {
    setLoading(true)

    let data = await fcStakingNft(isData.id, num);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Mining Success", "Success", 10000);
      handleClose()
    }
    // const _checkApproveNft = await checkApproveNft(isData.id);
    // console.log(_checkApproveNft.sttMarket);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(false);
  };


  return (
    <div className="site-content site-main-content__buy-sft page-profit">
      {/* <ModalConnect/> */}
      {
        showNotifi ?
          <ModalConfirm close={() => setShowNotifi(false)} />
          :
          null
      }
      <div className="hld30"></div>
      <div className=" max-w-1136">
        <div className="s1">
          <div className="title_text_main-icon ">
            <h2 className="font-main">0.00 BNB</h2>
            <div className="icon"><img src="img/icon_m.png" alt="" /> </div>

          </div>
          <div className="tt">
            <div className="l font-second">Your Claimable:</div>
            <div className="r color-y  font-second">0.00 BNB</div>
          </div>
        </div>

        <div className="s2 row m-0 mt-50 ">
          <div className="s2-1 col-sm-12 col-md-6 col-lg-4">
            <div className="item">
              <div className="l"><span className="font-second">ENDED IN</span></div>
              <div className="r">
                <div className="text-bold tdb"></div>
                <div className="text_ font-second">Na (UTC)</div>
              </div>
            </div>
          </div>
          <div className="s2-2 col-sm-12 col-md-6 col-lg-4">
            <button className="btn-claim"></button>
          </div>
          <div className="s2-3 col-sm-12 col-md-6 col-lg-4">
            <div className="item">
              <div className="l"><span className="font-second" style={{ fontSize: 20 }}>0.00 BNB</span></div>
              <div className="r">
                {/* <div className="text-bold tdb">2D 22H</div> */}
                <div className="text_ font-second">Total  BNB Sharing Profit </div>
              </div>
            </div>
          </div>
          <div className="s2-4 col-sm-12 col-md-12 col-lg-4">
            <button className="btn-claim"></button>
          </div>
        </div>

        <div className="s3 row m-0 mt-50 " style={{justifyContent: 'center'}}>
          {/* <div className="s3-1 col-sm-12 col-md-6 col-lg-4  mt-20">
            <div className="title_text_main-icon ">
              <div className="icon"><img src="img/icon5.png" alt="" /> </div>
              <h2 className="font-main">Top Holders (1-10)</h2>


            </div>
            <div className="list-profit mt-20">
              {[...Array(10)].map((_, index) => (<div className="item">
                <div className="l font-second"><span>0x0000...000000</span></div>
                <div className="r"><div>
                  <span className="r-1">
                    <img src="img/icon-r1.png" alt="" />
                  </span>
                  <span className="r-2 font-second">
                    0.00BNB
                  </span>
                  <span className="r-3">
                    <img src="img/icon-r2.png" alt="" />
                  </span>
                </div></div>
              </div>))
              }

            </div>
          </div> */}
          <div className="s3-2 col-sm-12 col-md-6 col-lg-4 mt-20">
            <div className="title_text_main-icon ">
              <div className="icon"><img src="img/icon4.png" alt="" /> </div>
              <h2 className="font-main">NFTs HOLDER REWARD</h2>
            </div>
            <div className="list-profit mt-20">
              {[...Array(10)].map((_, index) => (<div className="item">
                <div className="l font-second"><span>0x0000...000000</span></div>
                <div className="r"><div>
                  <span className="r-1">
                    <img src="img/icon-r1.png" alt="" />
                  </span>
                  <span className="r-2 font-second">
                    0.00BNB
                  </span>
                  <span className="r-3">
                    <img src="img/icon-r2.png" alt="" />
                  </span>
                </div></div>
              </div>))
              }

            </div>
            {/* <div className="all-btn-list  mt-20"> <button className="font-second">All</button> </div> */}
          </div>
          {/* <div className="s3-3 col-sm-12 col-md-6 col-lg-4  mt-20">
            <div className="title_text_main-icon ">

              <div className="icon"><img src="img/icon5.png" alt="" /> </div>
              <h2 className="font-main">Top Holders (11-20)</h2>
            </div>

            <div className="list-profit mt-20">
              {[...Array(10)].map((_, index) => (<div className="item">
                <div className="l font-second"><span>0x0000...000000</span></div>
                <div className="r"><div>
                  <span className="r-1">
                    <img src="img/icon-r1.png" alt="" />
                  </span>
                  <span className="r-2 font-second">
                    0.00BNB
                  </span>
                  <span className="r-3">
                    <img src="img/icon-r2.png" alt="" />
                  </span>
                </div></div>
              </div>))
              }

            </div>
          </div> */}

        </div>
      </div>
      <div className="hld180"></div>
    </div>
  );
};

export default Profit;
