import Web3 from "web3";

const getAccount = () => {
  return async (dispatch) => {
    const web3 = new Web3(Web3.givenProvider);

    setInterval(() => {
      if (window.localStorage.getItem('isConnect') == 'disConnect') {
        dispatch({
          type: "GET_ADDRESS",
          payload: null,
        });
        return
      }

      // if (window.localStorage.getItem('typeConnect') == 'bitkeep') {
      //   if (window.bitkeep) {
      //     window?.bitkeep?.ethereum.enable().then((accounts) => {
      //       if (accounts.length) {
      //         dispatch({
      //           type: "GET_ADDRESS",
      //           payload: accounts[0],
      //         });
      //       } else {
      //         dispatch({
      //           type: "GET_ADDRESS",
      //           payload: null,
      //         });
      //       }
      //     });
      //   }
      // } else {
      if (window.ethereum) {
        // const web3 = new Web3(Web3.givenProvider);
        const accounts = web3.eth.getAccounts().then((accounts) => {
          if (accounts.length) {
            dispatch({
              type: "GET_ADDRESS",
              payload: accounts[0],
            });
          } else {
            dispatch({
              type: "GET_ADDRESS",
              payload: null,
            });
          }
        });
        // window.ethereum.getAccounts().then((accounts) => {
        //   if (accounts.length) {
        //     dispatch({
        //       type: "GET_ADDRESS",
        //       payload: accounts[0],
        //     });
        //   } else {
        //     dispatch({
        //       type: "GET_ADDRESS",
        //       payload: null,
        //     });
        //   }
        // });

        // window.ethereum.enable().then((accounts) => {
        //   if (accounts.length) {
        //     dispatch({
        //       type: "GET_ADDRESS",
        //       payload: accounts[0],
        //     });
        //   } else {
        //     dispatch({
        //       type: "GET_ADDRESS",
        //       payload: null,
        //     });
        //   }
        // });
      }
      // }


    }, 300);
  };
};

export { getAccount };
