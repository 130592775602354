import React, { useRef, useEffect } from 'react';

const LoadingButton = ({ className }) => {
    return (
      <button className={className} style={{ width: 200 }}>
        <div className="spinner-border spinner-border-sm" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </button>
    );
  };

export default LoadingButton;