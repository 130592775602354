import React, { useState } from "react";

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    return (
        <ul className="ant-pagination" unselectable="unselectable">
            <li
                title="Previous Page"
                className={`ant-pagination-prev ${currentPage === 1 ? "ant-pagination-disabled" : ""
                    }`}
                aria-disabled={currentPage === 1}
            >
                <button
                    className="ant-pagination-item-link"
                    type="button"
                    tabIndex="-1"
                    disabled={currentPage === 1}
                    onClick={() => onPageChange(currentPage - 1)}
                >
                    <span
                        role="img"
                        aria-label="left"
                        className="anticon anticon-left"
                    >
                        <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="right"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path d="M724 218.3V141c0-6.7-7.7-10.4-12.9-6.3L260.3 486.8a31.86 31.86 0 000 50.3l450.8 352.1c5.3 4.1 12.9.4 12.9-6.3v-77.3c0-4.9-2.3-9.6-6.1-12.6l-360-281 360-281.1c3.8-3 6.1-7.7 6.1-12.6z"></path>
                        </svg>
                    </span>
                </button>
            </li>
            {
                totalPages >= 7 ?
                    <>
                        <li
                            key={1}
                            title={"1"}
                            className={`ant-pagination-item ${currentPage === 1 ? "ant-pagination-item-active" : ""
                                }`}
                            tabIndex="0"
                        >
                            <a rel="nofollow" onClick={() => onPageChange(1)}>
                                {1}
                            </a>
                        </li>
                        {
                            currentPage > 2 ?
                                <>
                                    <li
                                        title={`Previous ${currentPage - 1} Pages`}
                                        tabIndex="0"
                                        className={`ant-pagination-jump-prev ant-pagination-jump-prev-custom-icon ${currentPage === 2 ? "ant-pagination-disabled" : ""}`}
                                    >
                                        <a className="ant-pagination-item-link" onClick={() => { currentPage != 2 ? onPageChange(currentPage - 1) : onPageChange(currentPage) }}>
                                            <div className="ant-pagination-item-container">
                                                <span
                                                    role="img"
                                                    aria-label="double-left"
                                                    className="anticon anticon-double-left ant-pagination-item-link-icon"
                                                >
                                                    <svg
                                                        viewBox="64 64 896 896"
                                                        focusable="false"
                                                        data-icon="double-left"
                                                        width="1em"
                                                        height="1em"
                                                        fill="currentColor"
                                                        aria-hidden="true"
                                                    >
                                                        <path d="M272.9 512l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L186.8 492.3a31.99 31.99 0 000 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H532c6.7 0 10.4-7.7 6.3-12.9L272.9 512zm304 0l265.4-339.1c4.1-5.2.4-12.9-6.3-12.9h-77.3c-4.9 0-9.6 2.3-12.6 6.1L490.8 492.3a31.99 31.99 0 000 39.5l255.3 326.1c3 3.9 7.7 6.1 12.6 6.1H836c6.7 0 10.4-7.7 6.3-12.9L576.9 512z"></path>
                                                    </svg>
                                                </span>
                                                <span className="ant-pagination-item-ellipsis">•••</span>
                                            </div>
                                        </a>
                                    </li>
                                    {
                                        currentPage < totalPages - 2 ?
                                            Array.from({ length: 3 }, (_, i) => i - 1 + currentPage).map((page) => (
                                                <li
                                                    key={page}
                                                    title={page.toString()}
                                                    className={`ant-pagination-item ${currentPage === page ? "ant-pagination-item-active" : ""
                                                        }`}
                                                    tabIndex="0"
                                                >
                                                    <a rel="nofollow" onClick={() => onPageChange(page)}>
                                                        {page}
                                                    </a>
                                                </li>
                                            ))
                                            : null
                                    }
                                </>
                                :
                                Array.from({ length: 3 }, (_, i) => i + 2).map((page) => (
                                    <li
                                        key={page}
                                        title={page.toString()}
                                        className={`ant-pagination-item ${currentPage === page ? "ant-pagination-item-active" : ""
                                            }`}
                                        tabIndex="0"
                                    >
                                        <a rel="nofollow" onClick={() => onPageChange(page)}>
                                            {page}
                                        </a>
                                    </li>
                                ))

                        }
                        {
                            currentPage < totalPages - 2 ?
                                <li
                                    title={`Next ${currentPage + 1} Pages`}
                                    tabIndex="0"
                                    className={`ant-pagination-jump-next ant-pagination-jump-next-custom-icon ${currentPage === totalPages ? "ant-pagination-disabled" : ""}`}
                                >
                                    <a className="ant-pagination-item-link" onClick={() => { currentPage != totalPages ? onPageChange(currentPage + 1) : onPageChange(currentPage) }}>
                                        <div className="ant-pagination-item-container">
                                            <span
                                                role="img"
                                                aria-label="double-right"
                                                className="anticon anticon-double-right ant-pagination-item-link-icon"
                                            >
                                                <svg
                                                    viewBox="64 64 896 896"
                                                    focusable="false"
                                                    data-icon="double-right"
                                                    width="1em"
                                                    height="1em"
                                                    fill="currentColor"
                                                    aria-hidden="true"
                                                >
                                                    <path d="M533.2 492.3L277.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H188c-6.7 0-10.4 7.7-6.3 12.9L447.1 512 181.7 851.1A7.98 7.98 0 00188 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5zm304 0L581.9 166.1c-3-3.9-7.7-6.1-12.6-6.1H492c-6.7 0-10.4 7.7-6.3 12.9L751.1 512 485.7 851.1A7.98 7.98 0 00492 864h77.3c4.9 0 9.6-2.3 12.6-6.1l255.3-326.1c9.1-11.7 9.1-27.9 0-39.5z"></path>
                                                </svg>
                                            </span>
                                            <span className="ant-pagination-item-ellipsis">•••</span>
                                        </div>
                                    </a>
                                </li>
                                : Array.from({ length: 3 }, (_, i) => i + totalPages - 3).map((page) => (
                                    <li
                                        key={page}
                                        title={page.toString()}
                                        className={`ant-pagination-item ${currentPage === page ? "ant-pagination-item-active" : ""
                                            }`}
                                        tabIndex="0"
                                    >
                                        <a rel="nofollow" onClick={() => onPageChange(page)}>
                                            {page}
                                        </a>
                                    </li>
                                ))
                        }
                        <li
                            key={totalPages}
                            title={totalPages.toString()}
                            className={`ant-pagination-item ${currentPage === totalPages ? "ant-pagination-item-active" : ""
                                }`}
                            tabIndex="0"
                        >
                            <a rel="nofollow" onClick={() => onPageChange(totalPages)}>
                                {totalPages}
                            </a>
                        </li>
                    </>
                    :
                    Array.from({ length: totalPages }, (_, i) => i + 1).map((page) => (
                        <li
                            key={page}
                            title={page.toString()}
                            className={`ant-pagination-item ${currentPage === page ? "ant-pagination-item-active" : ""
                                }`}
                            tabIndex="0"
                        >
                            <a rel="nofollow" onClick={() => onPageChange(page)}>
                                {page}
                            </a>
                        </li>
                    ))

            }

            <li
                title="Next Page"
                className={`ant-pagination-next ${currentPage === totalPages ? "ant-pagination-disabled" : ""
                    }`}
                aria-disabled={currentPage === totalPages}
            >
                <button
                    className="ant-pagination-item-link"
                    type="button"
                    tabIndex="-1"
                    disabled={currentPage === totalPages}
                    onClick={() => onPageChange(currentPage + 1)}
                >
                    <span
                        role="img"
                        aria-label="right"
                        className="anticon anticon-right"
                    >
                        <svg
                            viewBox="64 64 896 896"
                            focusable="false"
                            data-icon="left"
                            width="1em"
                            height="1em"
                            fill="currentColor"
                            aria-hidden="true"
                        >
                            <path d="M765.7 486.8L314.9 134.7A7.97 7.97 0 00302 141v77.3c0 4.9 2.3 9.6 6.1 12.6l360 281.1-360 281.1c-3.9 3-6.1 7.7-6.1 12.6V883c0 6.7 7.7 10.4 12.9 6.3l450.8-352.1a31.96 31.96 0 000-50.4z"></path>
                        </svg>
                    </span>
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
